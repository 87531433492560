<template>
  <div class="LoginPage">
    <header-cmp />
    <main class="main-content">
      <customer-care-login-cmp-extended
        class="pt-11 pt-lg-14"
        :data-api-url="$context.loginApi"
        data-request-method="post"
        :data-bootstrap-container="true"
        data-submit-aria-label="Prosegui"
        :data-submit-enabled-on-mounted="false"
        :data-has-email="false"
        :data-has-username="true"
        style="transform: scale3d(0.8, 0.8, 0.8); transform-origin: center top"
      >
        <template #heading>
          <heading-cmp class="pb-8">Login</heading-cmp>
        </template>
        <template #title>
          <heading-cmp data-tag="h3" class="header-link pt-5 mb-1">
            Consulta la sezione <a :href="$context.faqUrl" class="text-color-secondary">FAQ</a> per avere maggiori informazioni.
          </heading-cmp>
          <heading-cmp data-tag="h3" class="header-link pt-1 mb-1">
            <a :href="$context.recoveryUrl" class="text-color-secondary">Se stai effettuando il primo accesso CLICCA QUI per richiedere la password</a>
          </heading-cmp>
          <heading-cmp data-tag="h3" class="underlined pt-1 mb-5">
            Inserisci le credenziali
          </heading-cmp>
        </template>
        <template #username-label>Indirizzo mail</template>
        <template #password-label>Password</template>
        <template #password-recovery>
          <span class="d-block pb-5">
            Se hai dimenticato la password, <a :href="$context.recoveryUrl" class="text-color-secondary">CLICCA QUI</a>, per recuperarla
          </span>
        </template>
        <template #submit>PROSEGUI</template>
      </customer-care-login-cmp-extended>
    </main>
    <footer-cmp />
    <app-loader-cmp />
    <svg-icons-cmp />
  </div>
</template>

<script>
import AppLoaderCmp from '@components/app-loader-cmp';
import CustomerCareLoginCmpExtended from '@components/customer-care-login-cmp-extended';
import FooterCmp from '@components/footer-cmp';
import HeaderCmp from '@components/header-cmp';
import HeadingCmp from '@components/heading-cmp';
import SvgIconsCmp from '@components/svg-icons-cmp';

export default {
  name: 'LoginPage',
  components: {
    AppLoaderCmp,
    CustomerCareLoginCmpExtended,
    FooterCmp,
    HeaderCmp,
    HeadingCmp,
    SvgIconsCmp,
  },
  mounted() {
    this.$store.dispatch('setLoading', false);
    this.$store.dispatch('setAppReady', true);
  },
};
</script>

<style lang="scss" scoped>
@import '~$scss/variables';

.LoginPage {
  .main-content {
    min-height: calc(
      100vh - #{fetch($header-height, xs)} - #{fetch($footer-height, xs)}
    );

    @include media-breakpoint-up(md) {
      min-height: calc(
        100vh - #{fetch($header-height, md)} - #{fetch($footer-height, md)}
      );
    }

    @include media-breakpoint-up(lg) {
      min-height: calc(
        100vh - #{fetch($header-height, lg)} - #{fetch($footer-height, lg)}
      );
    }
  }
}
</style>
