import '../assets';
import Vue from 'vue';
import VueBootstrapper from 'vue-bootstrapper';
import VueViewportManager from 'vue-viewport-manager';
import store from '@store';
import LoginPage from '@components/login-page';
import { debug, colorLog, brain, prohibited } from '@helpers/utils.js';
import SentryInitializer, { canSenty } from '@helpers/sentry_initializer';
import '@helpers/interceptors';

Vue.use(VueBootstrapper, { store });
Vue.use(VueViewportManager, {
  store,
  breakpoints: { xs: 0, sm: 380, md: 698, lg: 1024, xl: 1314 },
});

/* eslint-disable */
if (debug) {
  debug &&
    colorLog({
      icon: brain,
      label: 'environment',
      message: `${process.env.VUE_APP_ENV}`,
      themeName: 'orange',
    });
  debug &&
    colorLog({
      icon: brain,
      label: 'environment',
      message: `Vue added to window `,
      themeName: 'orange',
    });
  debug &&
    colorLog({
      icon: brain,
      label: 'environment',
      message: `Devtools enabled `,
      themeName: 'orange',
    });
  window.Vue = Vue;
  Vue.config.devtools = true;
} else {
  colorLog({
    icon: brain,
    label: 'welcome',
    message: `${process.env.VUE_APP_ENV}`,
    themeName: 'vue',
  });
}

if (!canSenty) {
  colorLog({
    icon: prohibited,
    label: 'environment',
    message: `Sentry disabled`,
    themeName: 'red2',
  });
}
/* eslint-enable */

/*
    https://4c26933f1a5e40349ea3f5ca6aba1ef9@sentry.frankhood.dev/28
    https://sentry.frankhood.dev/organizations/frankhood/issues/?project=28
*/

SentryInitializer(Vue, process.env.VUE_APP_SENTRY, process.env.VUE_APP_ENV);

new Vue({
  el: '#app-root',
  store: store,
  components: { LoginPage },
});
