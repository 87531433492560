<template>
  <customer-care-login-cmp class="CustomerCareLoginCmpExtended" v-bind="$attrs">
    <template v-for="(_, name) in $scopedSlots" v-slot:[name]="data">
      <slot :name="name" v-bind="data" />
    </template>
  </customer-care-login-cmp>
</template>

<script>
import { CustomerCareLoginCmp } from '@frankhoodbs/lib';

export default {
  name: 'CustomerCareLoginCmpExtended',
  components: {
    CustomerCareLoginCmp: {
      extends: CustomerCareLoginCmp,
      methods: {
        handleSuccessCall() {
          location.href = this.$context.successUrl;
        },
      },
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~$scss/form-variables';

.CustomerCareLoginCmpExtended {
  --form-element-distance: 0 0 #{fetch($spacers, 5)};

  ::v-deep .SubmitCmp {
    --form-submit-font: #{$form-submit-font};
    @include responsive-properties(--form-submit-padding, $form-submit-padding);
    --form-submit-border: 3px solid #{fetch($colors, secondary)};
    --form-color-bg-submit: #{fetch($colors, secondary)};
    --form-color-submit: #{$form-color-submit};

    .form-group {
      .form-control__wrapper {
        .CtaButtonCmp {
          --cta-button-display: flex;
          --cta-button-width: 100%;

          @include media-breakpoint-up(lg) {
            font-size: 19px;
          }
        }
      }
    }
  }
}
</style>
